import React, { useState } from "react";
import Logo from "../Header/Logo";
import { ROUTES } from "../../constants/constant";
import { NavLink } from "react-router-dom";
import DynamicIcon from "../Common/DynamicIcon";
import GetDevice from "../../hooks/GetDevice";

const SideBar = ({setShowOverlay}) => {
  const [showMobileLogo ,setShowMobileLogo] = useState(window.innerWidth < 1200 ? true : false);
  const {isShowDesktop} = GetDevice();

  const showSidebar = () => {
    const root = document.documentElement;
    const currentSidebarWidth = getComputedStyle(root).getPropertyValue('--sidebar-width').trim();
    if (currentSidebarWidth === "250px") {
      root.style.setProperty(`--${"sidebar-width"}`, "60px");
      setShowMobileLogo(true)
      setShowOverlay(false)
    } else {
      root.style.setProperty(`--${"sidebar-width"}`, "250px");
      setShowMobileLogo(false)
      setShowOverlay(true)
    }
  }

  return (
    <>
      <div className="menu" onClick={() => showSidebar()}>
        <span className="pointer">{ showMobileLogo ? <DynamicIcon name={'topmenu'}/> : <DynamicIcon name={'topmenuclose'}/>}</span>
        <Logo showMobileLogo={showMobileLogo} />

      </div>
      
      <nav className="navbar">
        <ul>
          {ROUTES.map((route, index) => {
            return (
              route.sidebar && (
                <li key={index}>
                  <NavLink
                    to={route.path}
                    end
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                    onClick={() => !isShowDesktop && showSidebar()}
                  >
                  <DynamicIcon name={route.iconName} />
                    {route.componentName}
                  </NavLink>
                </li>
              )
            );
          })}
        </ul>
      </nav>
      
    </>
  );
};

export default SideBar;
