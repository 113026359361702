import { combineReducers } from 'redux';
import commonSlice from './slices/commonSlice';
import AuthSlice from './slices/AuthSlice';
import StoreSlice from './slices/StoreSlice';
import SettingSlice from './slices/SettingSlice';
import OrderSlice from './slices/OrderSlice';

const combineReducer = combineReducers({
	common: commonSlice,
	auth: AuthSlice,
	store: StoreSlice,
	setting: SettingSlice,
	orders: OrderSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
		state = undefined;
  }
  return combineReducer(state, action);
}

export default rootReducer;
