import React, { useEffect, useState } from "react";

const GetDevice = () => {
  const [isShowDesktop, setIsShowDesktop] = useState(
    window.innerWidth > 769 ? true : false
  );
  const [isTabActive, setIsTabActive] = useState(
    window.innerWidth > 991 ? false : true
  );
  useEffect(() => {
    const handleResize = function () {
      const root = document.documentElement;
      if (window.innerWidth < 1200 || document.innerWidth < 1200) {
        setIsShowDesktop(false);
        root.style.setProperty(`--${"sidebar-width"}`, "60px");
      } else {
        setIsShowDesktop(true);
        root.style.setProperty(`--${"sidebar-width"}`, "250px");
      }

      if (window.innerWidth < 992 || document.innerWidth < 992) {
        setIsTabActive(true)
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize()
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return { isShowDesktop: isShowDesktop , isTabActive: isTabActive};
};

export default GetDevice;
