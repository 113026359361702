export const BaseToStr = (base) =>{
  function decodeHtmlEntities(str) {
    let textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  }

  let base64Decoded = atob(base);
  let jsonDecoded = JSON.parse(base64Decoded);
  let decodedHtml = decodeHtmlEntities(jsonDecoded);

  return decodedHtml
}

export const is_valid_indian_number = (number) => {
  const regex = /^[0-9]+$/;
  return regex.test(number);
};

export const json_to_form_data = (obj) => {
  const form_data = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      form_data.append(key, obj[key]);
    }
  }

  return form_data;
};


export const convertIntoHtml = (rawHtmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(rawHtmlString, 'text/html');

  return doc.body.textContent;  
}

// convert customization data 
export const convertApiData = async (apiData) => {
  const setting = apiData || {};

  // Helper function to map keys
  const mapKeys = (source, keyMapping) => {
      const target = {};
      for (const [sourceKey, targetKey] of Object.entries(keyMapping)) {
          if (source[sourceKey] !== undefined) {
              target[targetKey] = source[sourceKey];
          }
      }
      return target;
  };

  const convertedData = {};

  // Map for brand keys
  const brandKeyMapping = {
      brand_category: "category",
      brand_theme_color: "theme_color",
      brand_theme_secondary_color: "theme_secondary_color",
      brand_seamless_auth: "seamless_auth",
      brand_tnc: "tnc",
      brand_privacy_policy: "privacy_policy"
  };

  // Map for element keys
  const elementsKeyMapping = {
      element_show_mrp_with_strike: "show_mrp_with_strike",
      element_collapse_order_summery: "collapse_order_summery",
      element_item_count_total: "item_count_total"
  };

  // Map for login keys
  const loginKeyMapping = {
      login_banner_status: "banner_status",
      login_theme_color: "theme_color",
      login_theme_secondary_color: "theme_secondary_color",
      login_same_as_primary_color: "same_as_primary_color",      
      login_same_as_primary_secondary_color: "same_as_primary_secondary_color",
      login_banner_text: "banner_text",
      login_cta_text: "cta_text",
  };

  // Map for address keys
  const addressKeyMapping = {
      address_banner_status: "banner_status",
      address_theme_color: "theme_color",
      address_theme_secondary_color: "theme_secondary_color",
      address_banner_text: "banner_text",
      address_cta_text: "cta_text",
      address_shipping_text: "shipping_text",
      address_same_as_primary_color: "same_as_primary_color",      
      address_same_as_primary_secondary_color: "same_as_primary_secondary_color",
      
  };

  // Map for payment keys
  const paymentKeyMapping = {
      payment_banner_status: "banner_status",
      payment_theme_color: "theme_color",
      payment_theme_secondary_color: "theme_secondary_color",
      payment_same_as_primary_color: "same_as_primary_color",      
      payment_same_as_primary_secondary_color: "same_as_primary_secondary_color",
      payment_banner_text: "banner_text"
  };

  // Map for custom keys
  const customKeyMapping = {
      custom_custom_css: "custom_css"
  };

  convertedData.brand = mapKeys(setting, brandKeyMapping);
  convertedData.elements = mapKeys(setting, elementsKeyMapping);
  convertedData.login= mapKeys(setting, loginKeyMapping);
  convertedData.address= mapKeys(setting, addressKeyMapping);
  convertedData.payment= mapKeys(setting, paymentKeyMapping);
  convertedData.custom = mapKeys(setting, customKeyMapping);

  return convertedData;
}



export const SettingInitialState = {
  brand: {
    category : "",
    theme_color: "#000000",
    theme_secondary_color: "#ffffff",
    seamless_auth: '0',
    tnc : "",
    privacy_policy: ""
  },
  elements: {
    show_mrp_with_strike: '',
    collapse_order_summery: '',
    item_count_total: ""
  },
  login: {
    banner_status: '0',
    theme_color: "#127d4b",
    theme_secondary_color: "#ffffff",
    banner_text: "",
    cta_text:"",
    same_as_primary_color: '',
    same_as_primary_secondary_color: ''
  },
  address: {
    banner_status: 0,
    theme_color: "#127d4b",
    theme_secondary_color: "#ffffff",
    banner_text: "",
    cta_text: "",
    shipping_text: "",
    same_as_primary_color: '',
    same_as_primary_secondary_color: '',
    gst_capture: 0,
    email_capturing: 0
  },
  payment: {
    banner_status: 0,
    theme_color: "#127d4b",
    theme_secondary_color: "#ffffff",
    banner_text: "",
    same_as_primary_color: '',
    same_as_primary_secondary_color: ''
  },
  custom: {
    custom_css: ""
  }
}

// convert all value in string
export const stringValues = (values) => {
  const res = Object.keys(values).reduce((acc, key) => {
    acc[key] = values[key].toString();
    return acc;
  }, {});
  return res;
}

// dateFormatter
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  
  return `${formattedDate} at ${hours}:${minutes}`;
}

export const formatDatePayload = (date) => {
  if (!date) {
    return ''
  }

  if (typeof date  == 'string') {
    return date
  }
 
  const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  return formattedDate
}
