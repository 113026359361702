import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Image from '../Common/Image'
import DynamicIcon from '../Common/DynamicIcon'
import profileImage from '../../assets/images/profile.png'

const ProfileCard = () => {
  
  return (
    <div className="profile-dropdown">       
     <Image src={profileImage} alt={'user avatar'} />
     <ul className={`profile-dropdown-content`}>
      <li><Link to={'/user'}><DynamicIcon name={'usermanagement'} />{'Account'}</Link></li>
      <li><Link to={'/logout'}><DynamicIcon name={'logout'} />{'logout'}</Link></li>
     </ul>
    </div>
  )
}

export default ProfileCard