import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import TopHeader from "../components/Header/TopHeader";
import GetDevice from "../hooks/GetDevice";
import { unauthorizedRoute } from "../constants/constant";

const Page = ({ Component, title }) => {
  const {pathname} = useLocation()
  const {isShowDesktop} = GetDevice()
  const [showOverlay , setShowOverlay] = useState(false);

  useEffect(() => {
    document.title = title;
  }, [title]);

  if (unauthorizedRoute.includes(pathname)) {
    return <Component />
  }

  return (
    <div className="wrapper">
      {
        (showOverlay && !isShowDesktop) && <div className="overlay"></div>
      }
      <aside className={`sidebar ${isShowDesktop ? '' : 'mobile-sidebar'}`}><SideBar setShowOverlay={setShowOverlay} /></aside>
      <div className="body-wrapper">
        <header className="header"><TopHeader /></header>
        <main className="components">
          <Component />
        </main>
      </div>
    </div>
  );
};

export default Page;
