import React from "react";
import { HiOutlineCreditCard } from "react-icons/hi2";
import { CiBank } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { CiGrid42 } from "react-icons/ci";
import { PiHandbag } from "react-icons/pi";
import { BsGraphUpArrow } from "react-icons/bs";
import { CiDiscount1 } from "react-icons/ci";
import { TbUserCog } from "react-icons/tb";
import { BsCartX } from "react-icons/bs";
import { HiOutlineLogout } from "react-icons/hi";
import { BiLoaderCircle } from "react-icons/bi";
import { IoMdArrowRoundForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { CiFaceMeh } from "react-icons/ci";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { CiMail } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoChevronBack } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import { BsFillSendPlusFill } from "react-icons/bs";
import { VscListTree } from "react-icons/vsc";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";
import { FaCaretUp } from "react-icons/fa";
import { BiSolidCheckbox } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import { FaBold } from "react-icons/fa6";
import { FaUnderline } from "react-icons/fa6";
import { FaItalic } from "react-icons/fa6";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { IoWalletOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { LiaUserLockSolid } from "react-icons/lia";
import { FaCheck } from "react-icons/fa";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { FaAngleDoubleRight } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleDoubleLeft } from "react-icons/fa";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { RiMenuFoldLine } from "react-icons/ri";
import { RiMenuFold2Line } from "react-icons/ri";
import { IoCopyOutline } from "react-icons/io5";


const iconComponents = {
  creditCard: HiOutlineCreditCard,
  netbanking: CiBank,
  check : FaCheckCircle,
  order : PiHandbag,
  usermanagement : TbUserCog,
  discount : CiDiscount1,
  analytics : BsGraphUpArrow,
  abandonedcart : BsCartX,
  dashboard : CiGrid42,
  logout: HiOutlineLogout,
  loader: BiLoaderCircle,
  settings: VscListTree,
  arrowright: IoMdArrowRoundForward,
  close: IoClose,
  threedots: BsThreeDotsVertical,
  search: IoSearch,
  notfound: CiFaceMeh,
  menu: HiOutlineMenuAlt1,
  hi: CiMail,
  mail: CiMail,
  lock: CiLock,
  back: IoChevronBack,
  eye: MdOutlineRemoveRedEye,
  hide: FaRegEyeSlash,
  adduser: IoMdPersonAdd,
  send: BsFillSendPlusFill,
  customize: MdOutlineDashboardCustomize,
  up: FaCaretUp,
  down: FaCaretDown,
  box: BiSolidCheckbox,
  upload: FiUpload,
  bold: FaBold,
  underline: FaUnderline,
  italic: FaItalic,
  drag: RxDragHandleHorizontal,
  wallet: IoWalletOutline,
  edit: FaRegEdit,
  userlock: LiaUserLockSolid,
  yes: FaCheck,
  alert: TbAlertTriangleFilled,
  gotolast:  FaAngleDoubleRight, 
  gotoprev:  FaAngleRight, 
  gotonext:  FaAngleLeft, 
  gotofirst:  FaAngleDoubleLeft, 
  calendar : IoCalendarNumberOutline,
  topmenu: RiMenuFold2Line,
  topmenuclose: RiMenuFoldLine,
  clipboard: IoCopyOutline
};

const DynamicIcon = ({ name }) => {
  const IconComponent = iconComponents[name.toLowerCase()]; // Get the corresponding icon component

  if (!IconComponent) {
    console.error(`Icon "${name}" not found`);
    return null;
  }

  return (
      <IconComponent />
  );
};

export default DynamicIcon;
