import * as Yup from 'yup';


export const create_account_validation_schema = Yup.object().shape({
    first_name: Yup.string()
        .required('First name is required')
        .min(3, 'Name must be between 3 and 32 characters! only alphabets allowed.'),
    last_name: Yup.string()
        .required('Last name is required')
        .min(3, 'Name must be between 3 and 32 characters! only alphabets allowed.'),
    brand_name: Yup.string()
        .required('Brand name is required')
        .min(3, 'Name must be between 3 and 32 characters! only alphabets allowed.'),
    email: Yup.string()
        .email('Please enter valid Email')
        .required('Email is required'),
    mobile: Yup.string()
        .required('Mobile number is required')
        .matches(/^[1-9][0-9]{9}$/, 'Mobile number must be exactly 10 digits and cannot start with 0'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/\d/, 'Password must contain at least one digit')
        .matches(/^[a-zA-Z0-9]*$/, 'Password can only contain alphanumeric characters'),
});



export const confirmation_otp_validation_schema = Yup.object().shape({
    otp: Yup.string()
      .required('OTP is required')
      .matches(/^[0-9]{6}$/, 'OTP must be a 6-digit number'),
});

export const login_validation_schema = Yup.object().shape({
    email: Yup.string()
        .email('Please enter valid Email')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/\d/, 'Password must contain at least one digit')
        .matches(/^[a-zA-Z0-9]*$/, 'Password can only contain alphanumeric characters'),
});

export const add_newStore_validation_schema = Yup.object().shape({
    store_name: Yup.string()
        .required('Store Name is required')
        .min(3, 'Name must be between 3 and 32 characters! only alphabets allowed.'),
    store_url: Yup.string()
        .required('Store Url is required')
        .url('Invalid URL format'),
    merchant_id: Yup.string()
        .required('Merchant Id is required')
        .min(1, 'Must be a Number')
        .matches(/^[0-9]+$/, 'Merchant Id must contain only numbers'),
});



// customization 

export const BrandFormvalidation =  Yup.object().shape({
    category : Yup.string(),
    theme_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme color is required'),
    theme_secondary_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme secondary color is required'),
    tnc: Yup.string(),
    privacy_policy: Yup.string()
})

export const ElementsFormValidation = Yup.object().shape({
    collapse_order_summery: Yup.string().required('Selection is required'),
    item_count_total: Yup.string().required('Selection is required'),
});

export const LoginFormValidation = Yup.object().shape({
    theme_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme color is required'),
    theme_secondary_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme color is required'),
    banner_text: Yup.string(),    
    same_as_primary_color: Yup.string(),
    same_as_primary_secondary_color: Yup.string(),
    cta_text:Yup.string()
})

export const AddressFormValidation = Yup.object().shape({
    theme_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme color is required'),
    theme_secondary_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme color is required'),
    banner_text: Yup.string(),    
    cta_text:Yup.string(),
    shipping_text:Yup.string(),
    same_as_primary_color: Yup.string(),
    same_as_primary_secondary_color: Yup.string(),
})

export const PaymentFormValidation = Yup.object().shape({
    theme_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme color is required'),
    theme_secondary_color: Yup.string()
        .matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color format')
        .required('Theme color is required'),
    same_as_primary_color: Yup.string(),
    same_as_primary_secondary_color: Yup.string(),
    banner_text: Yup.string(),    
})


// usermanagement validation
export const AddUserModalValidation = Yup.object().shape({
    user_name: Yup.string()
        .required('User name is required')
        .min(3, 'User name must be at least 3 characters')
        .max(50, 'User name must be at most 50 characters'),
    user_email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    user_mobile: Yup.string()
        .matches(/^[0-9]+$/, 'Mobile number must be only digits')
        .min(10, 'Mobile number must be at least 10 digits')
        .max(15, 'Mobile number must be at most 15 digits')
        .required('Mobile number is required'),
    role: Yup.string()
        .required('Role is required'),
    account: Yup.string()
        .required('Account is required')
}) 

// settings form validation
export const storeSettingValidation = Yup.object().shape({
    status: Yup.boolean(), 
    store_url: Yup.string().url('please enter correct Url format').required('Store url is required'),
    merchant_store_id: Yup.number().required('Store Id is required'),
    store_name: Yup.string()
        .min(3, 'Store name must be at least 3 characters')
        .max(50, 'Store name must be at most 50 characters')
        .required('Account is required'),
});

export const storeFlipKartValidation = Yup.object().shape({
    fk_address_status: Yup.string().required('Selection is required'),
    fk_rto_status: Yup.string().required('Selection is required'),
});
