import React, { Suspense, useEffect, useState} from 'react';
import { Navigate, Routes as ReactRoute, Route } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import Loader from './components/Loader/Loader';
import Page from './pages/Page';
import { ROUTES } from './constants/constant';
import http from './services/https';
import {
    set_dashboard,
    set_selected_store,
    update_store_list,
} from './redux/slices/StoreSlice';
import { set_user } from './redux/slices/AuthSlice';

import { registerService,getService } from './services/http.service';
import { set_status, set_payment_method } from './redux/slices/OrderSlice';

const NotFound = React.lazy(() => import('./pages/NotFound'));

function App() {
    const dispatch = useDispatch();
    const [initialLoad , setinitialLoad] = useState(true);
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { showLoader } = useSelector((state) => state.common);
    
    const Initiate = async () => {
        let response = await http({
            url: '/initiate',
        });
        let { selected_store, user, stores, statuses, payment_methods } = response;
        if (response?.dashboard) {
            dispatch(set_dashboard(response.dashboard))
        }
        dispatch(set_selected_store(selected_store || {}));
        dispatch(set_user(user || {}));
        dispatch(update_store_list(stores || []));
        dispatch(set_status(statuses || []));
        dispatch(set_payment_method(payment_methods || []));
        setinitialLoad(false);
    }
    //to repeat any call
    registerService('initiate', Initiate);
    useEffect(() => {
        if (initialLoad) {
            getService('initiate')();
        }
    },[]);

    // till we dont verify the login functionality
    if (initialLoad) {
        return <Loader />
    }

    return (
        <>
            {showLoader && <Loader />}
            <Suspense fallback={<Loader />}>
                <ReactRoute>
                    {ROUTES.map((route, i) => {
                        if (route.path === '/login' && isLoggedIn) {
                            return (
                                <Route
                                    key={i}
                                    path={route.path}
                                    element={<Navigate to="/" replace />}
                                />
                            );
                        }
                        
                        return ( isLoggedIn !== null && 
                            <Route
                                key={i}
                                path={route.path}
                                element={
                                    route.authRequired && !isLoggedIn ? (
                                        <Navigate to="/login" replace />
                                    ) : (
                                        <Page
                                            Component={route.component}
                                            title={route.title}
                                        />
                                    )
                                }
                            />
                        );
                    })}
                    {isLoggedIn !== null && <Route path="*" element={<NotFound />} />}
                </ReactRoute>
            </Suspense>
        </>
    );
}

export default App;
