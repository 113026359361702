import React from "react";
import Image from "../Common/Image";
import logo from "../../assets/images/logo.svg";
import logoM from "../../assets/images/logo-m.png";

const Logo = ({ showMobileLogo }) => {
  return (
    <div className="brand-logo">
      {showMobileLogo ? (
          null
      ) : (
        <Image src={logo} alt={"brand-logo"} link="/" />
      )}
    </div>
  );
};

export default Logo;
