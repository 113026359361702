import React from "react";
import { Link } from "react-router-dom";

const Image = ({ src, link, alt }) => {
  if (!link) return <img src={src} alt={alt} />;
  
  return <Link to={link}><img src={src} alt={alt} /></Link>;
};

export default Image;
