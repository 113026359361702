import { createSlice } from '@reduxjs/toolkit';
import { SettingInitialState } from '../../constants/helper';

const SettingSlice = createSlice({
	name: 'setting',
	initialState: {
		settings: SettingInitialState,
		update_tab: 'brand',
		update_next_tab: '',
		show_change_modal: '',
		reset_changes : false,
		active_formik_values : {}
	},
	reducers: {
		set_setting_value: (state, action) => {
			state.settings = action.payload;
		},
		set_update_tab: (state , action) => {
			state.update_tab = action.payload
		},
		set_update_next_tab: (state , action) => {
			state.update_next_tab = action.payload
		},
		set_show_change_modal : (state , action) => {
			state.show_change_modal = action.payload
		},
		set_reset_changes:(state ,action) => {
			state.reset_changes = action.payload
		},
		set_active_formik_values:(state ,action) => {
			state.active_formik_values = Object.assign(state.active_formik_values, action.payload)
		}
	},
});

export const { set_setting, 
	set_setting_value,
	set_update_tab, 
	set_show_change_modal, 
	set_reset_changes,
	set_update_next_tab, 
	set_active_formik_values} = SettingSlice.actions;
export default SettingSlice.reducer;
