import React from "react";

const SYSTEM_CONSTANTS = {
  BASE_URL: process.env.REACT_APP_API_URL,
  APP_NAME: process.env.REACT_APP_NAME,
};

const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const Customization = React.lazy(() => import("../pages/Dashboard/Customization"));
const AbandonedCart = React.lazy(() => import("../pages/Dashboard/AbandonedCart"));
const Analytics = React.lazy(() => import("../pages/Dashboard/Analytics"));
const UserManagement = React.lazy(() => import("../pages/Dashboard/UserManagement"));
const Order = React.lazy(() => import("../pages/Dashboard/Order"));
const Settings = React.lazy(() => import("../pages/Dashboard/Settings"));
const Discount = React.lazy(() => import("../pages/Dashboard/Discount"));
const LogOut = React.lazy(() => import("../pages/LogOut"));
const LogIn = React.lazy(() => import("../pages/UnAuthorized/Login"));
const CreateAccount = React.lazy(() => import("../pages/UnAuthorized/CreateAccount"));
const ForgotPassword = React.lazy(() => import("../pages/UnAuthorized/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/UnAuthorized/ResetPassword"));
const EnterOtp = React.lazy(() => import("../pages/UnAuthorized/EnterOtp"));

export const ROUTES = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
    componentName: "Dashboard",
    iconName: "Dashboard",
    title: SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
  {
    path: "/login",
    exact: true,
    component: LogIn,
    componentName: "LogIn",
    iconName: "LogIn",
    title: "Login" + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: false,
    sidebar: false,
  },
  {
    path: "/forget-password",
    exact: true,
    component: ForgotPassword,
    componentName: "Forget",
    iconName: "Forget",
    title: "Forget Password" + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: false,
    sidebar: false,
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPassword,
    componentName: "Forget",
    iconName: "Forget",
    title: "Reset Password" + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: false,
    sidebar: false,
  },
  {
    path: "/enter-otp",
    exact: true,
    component: EnterOtp,
    componentName: "OTP",
    iconName: "OTP",
    title: "OTP" + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: false,
    sidebar: false,
  },
  {
    path: "/signup",
    exact: true,
    component: CreateAccount,
    componentName: "signup",
    iconName: "signup",
    title: "Sign up" + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: false,
    sidebar: false,
  },
  {
    path: "/logout",
    exact: true,
    component: LogOut,
    componentName: "LogOut",
    iconName: "LogOut",
    title: "Logout" + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: false,
  },
  {
    path: "/order",
    exact: true,
    component: Order,
    componentName: "Orders",
    iconName: "Order",
    title: "Order | " + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
  {
    path: "/abandoned-cart",
    exact: true,
    component: AbandonedCart,
    componentName: "Abandoned Cart",
    iconName: "AbandonedCart",
    title: "Abandoned Cart | " + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
  {
    path: "/analytics",
    exact: true,
    component: Analytics,
    componentName: "Analytics",
    iconName: "Analytics",
    title: "Analytics | " + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
  {
    path: "/discount",
    exact: true,
    component: Discount,
    componentName: "Discount",
    iconName: "Discount",
    title: "Discount | " + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
  {
    path: "/user",
    exact: true,
    component: UserManagement,
    componentName: "User Management",
    iconName: "UserManagement",
    title: "User | " + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
  {
    path: "/api-detail",
    exact: true,
    component: Settings,
    componentName: "Settings",
    iconName: "Settings",
    title: "Setting | " + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
  {
    path: "/customize",
    exact: true,
    component: Customization,
    componentName: "Customize",
    iconName: "customize",
    title: "Customize | " + SYSTEM_CONSTANTS.APP_NAME,
    authRequired: true,
    sidebar: true,
  },
];

export const unauthorizedRoute = [
  "/login",
  "/forget-password",
  "/reset-password",
  "/enter-otp",
  "/signup",
];

export const INIT_CONSTANTS = SYSTEM_CONSTANTS;
