import axios from "axios";
import { INIT_CONSTANTS } from '../constants/constant';
import store from "../redux/store";
import { setIsAuthenticated,setCSRF } from '../redux/slices/AuthSlice';
import { update_show_loader } from '../redux/slices/commonSlice';

const ALLOWED_METHODS = ['PUT', 'DELETE', 'POST'];

const axiosInstance = axios.create({
    baseURL: INIT_CONSTANTS.BASE_URL,
    withCredentials: true,
});

const interceptHeader = (headers) => {
    if (!headers) {
        return;
    }
    if (headers["x-csrf-key"] && headers["x-csrf-value"]) {
        const csrf = {
            csrfKey: headers["x-csrf-key"],
            csrfValue: headers["x-csrf-value"],
        };
        store.dispatch(setCSRF({ ...csrf }));
    }
    typeof headers['is-authenticated'] != 'undefined' && store.dispatch(setIsAuthenticated(headers['is-authenticated']))
}

axiosInstance.interceptors.request.use(
    async (config) => {
        // check if its contains tokenId or not
        // if (!extractTokenId(config?.url)) {
        //     const { orderToken } = store?.getState()?.secure;
        //     config.headers["X-Order-Token"] = orderToken;
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
var retry_count = 0;
axiosInstance.interceptors.response.use(
    (response) => {
        retry_count = 1;
        interceptHeader(response.headers);
        if (response.status === 200) {
            return response.data;
        }
    },
    (error) => {
        if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_REQUEST') {
            retry_count++;
            if (retry_count < 3) {
                http({ url: "/health" });
            }
        }
        interceptHeader(error.response?.headers);
        return Promise.reject(error);
    }
);

//Buffer sequence
export class Buffer {
    constructor(handler) {
        var queue = [];
        function run() {
            var callback = function () {
                if (queue.length > 0) {
                    run();
                } else {
                    store.dispatch(update_show_loader(false));
                }
            };
            handler(queue.shift(), callback);
        }
        this.append = function (task) {
            queue.push(task);
            // eslint-disable-next-line eqeqeq
            if (queue.length == 1) {
                store.dispatch(update_show_loader(true));
                setTimeout(run, 100);
            }
        };
    }
}

export class Task {
    constructor(config, resolve = null, reject = null) {
        this.config = config;
        this.resolve = resolve;
        this.reject = reject;
    }
}

export function Handler(task, callback) {
    try {
        if (!task) {
            return;
        }
        let _config = task.config;

        if (ALLOWED_METHODS.includes(_config.method)) {
            const { csrfKey, csrfValue } = store?.getState()?.auth;
            _config.headers["X-Csrf-Key"] = csrfKey;
            _config.headers["X-Csrf-Value"] = csrfValue;
        }
        axiosInstance.request(_config).then(res => {
            if (res) {
                task.resolve(res.data);
            } else {
                throw res;
            }
        }, rej => {
            let _error_response = rej.response?.data;
            if (_error_response) {
                task.reject(_error_response);
            }
            task.reject(rej)
        }).finally(callback);
    } catch (error) {
        task.reject(error);
    }
}

const buffer = new Buffer(Handler);

export default async function http(options) {
    let config = {
        url: `${INIT_CONSTANTS.BASE_URL}/admin${options.url}`,
        method: options.method || 'GET',
        data: options.data || {},
        headers: options.headers || {},
    };

    let _promise = new Promise((resolve, reject) => {
        let _task = new Task({ ...config }, resolve, reject);
        buffer.append(_task);
    });
    return await _promise;
}