import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import http from '../../services/https';
import { update_store_list, set_selected_store, set_new_store_form, set_dashboard } from '../../redux/slices/StoreSlice';
import _ from 'lodash';

const StoreList = ({ reFetchStores , setReFetchStores}) => {
	const dispatch = useDispatch();
	const { isLoggedIn } = useSelector((store) => store.auth);
	const { storeList,selectedStore, lastSaveStore } = useSelector((store) => store.store);
	const handleClick = (event, store) => {
		event.preventDefault();
		SelectedStore(store);
		
	};
	
	const SelectedStore = async (store) => {
		if (!store) {
			return;
		}
		try {
			const res = await http({
				"url": "/store/" + store.storefront_id,
				"method":"PUT"
			});
			if (res?.dashboard) {
				dispatch(set_dashboard(res.dashboard))
			}
			if (store.storefront_id != selectedStore.storefront_id) {
				dispatch(set_selected_store(store));
			}
		} catch (e) {
			
		}
	}

	const GetStoreList = async () => {
		let _stores = await http({
			"url": "/stores",
		});

		setReFetchStores(false);
		let _new_store_popup = !_stores.length;
		dispatch(set_new_store_form(_new_store_popup));
		dispatch(update_store_list(_stores));
		SetSelectedstore(_stores);
	}

	const SetSelectedstore = (_stores) => {
		if (_stores.storefront_id != selectedStore.storefront_id) {
			return;
		}
		if (!(selectedStore?.storefront_id) && isLoggedIn) {
			let _store = null;
			if (lastSaveStore?.merchant_id) {
				_store = _stores.find(v => v.storefront_id === lastSaveStore.merchant_id);
			} else {
				_store = _stores.at(0);
			}
			SelectedStore(_store);
		} else {
			
		}
	}

	useEffect(() => {
		if (reFetchStores ) {
			GetStoreList();
		}
	}, [reFetchStores]);

	useEffect(() => { 
		if (storeList.length) {
			SetSelectedstore(storeList);
		}
	}, [storeList, selectedStore]);

	return (
		<>
			<Dropdown className="brand-selector">
				<Dropdown.Toggle id="dropdown-basic">
					{selectedStore?.name}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{storeList && storeList.map(store => {
						return <Dropdown.Item key={store.storefront_id} onClick={(e) => handleClick(e, store)}>{store.name} ({store.platform})</Dropdown.Item>
					})}
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

export default StoreList;
