import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const _storeInitialState = {
    storeList: [],
    selectedStore: {
        setting: {},
    },
    setting: {},
    reFetchStore: false,
    newStorePopup: false,
    lastSaveStore: {},
    userList: [],
	flipkart_services: {},
	dashboard: {}
};

const StoreSlice = createSlice({
	name: 'store',
	initialState: _.cloneDeep(_storeInitialState),
	reducers: {
		update_store_list: (state, action) => {
			state.storeList = action.payload;
		},
		set_selected_store: (state, action) => {
			state.selectedStore = action.payload;
			state.setting = state.selectedStore.setting;
		},
		set_refetch: (state, action) => {
			state.reFetchStore = action.payload;
		},
		set_new_store_form: (state, action) => {
			state.newStorePopup = action.payload;
		},
		set_last_save_store: (state, action) => {
			state.lastSaveStore = action.payload;
		},
		set_user_list: (state, action) => {
			state.userList = action.payload;
		},
		set_flipkart_services: (state , action) => {
			state.flipkart_services = action.payload
		},
		set_dashboard: (state , action) => {
			state.dashboard = action.payload
		},
		reset: (state, action) => _.cloneDeep(_storeInitialState),
	},
});

export const {
	update_store_list,
	set_selected_store,
	set_refetch,
	set_new_store_form,
	set_last_save_store,
	set_user_list,
	reset,
	set_flipkart_services,
	set_dashboard
} = StoreSlice.actions;
export default StoreSlice.reducer;
