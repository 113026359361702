import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const _commonInitialState = {
  toasts: [],
  modal: {},
  showLoader: false
};
const commonSlice = createSlice({
	name: 'common',
	initialState: _.cloneDeep(_commonInitialState),
	reducers: {
		update_toasts: (state, action) => {
			state.toasts = action.payload;
		},
		update_show_loader: (state, action) => {
			state.showLoader = action.payload;
		},
		reset: (state, action) => _.cloneDeep(_commonInitialState),
	},
});

export const { update_toasts, update_show_loader } = commonSlice.actions;
export default commonSlice.reducer;
