
const HttpServices = {};

//making common function call require many place same functionality 
export const registerService = (serviceName, serviceCall) => {
        if (typeof HttpServices[serviceName] == 'undefined') {
                HttpServices[serviceName] = serviceCall;
        } else {
        }
};

export const getService = (serviceName) => {
        //Debug code will remove when go to production
        try {
                throw new Error("just for caller");        
        } catch (e) {
        }
        //End
        
        if (typeof HttpServices[serviceName] != 'undefined') {
                return HttpServices[serviceName];
        }
        
        return () => { 
                console.log('service not Registered');
        }
};

