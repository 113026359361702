import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const _authInitialState = {
	isLoggedIn: null,
	userName: null,
	csrfKey: '',
	csrfValue: '',
	user: {},
};

const AuthSlice = createSlice({
	name: 'auth',
	initialState: _.cloneDeep(_authInitialState),
	reducers: {
		update_login: (state, action) => {
			state.isLoggedIn = action.payload.isLoggedIn;
			state.userName = action.payload.userName;
			state.userEmail = action.payload.userEmail;
		},
		logout: (state, action) => {
			state.isLoggedIn = false;
			state.userName = null;
            state.userEmail = '';
            state.user = {};
		},
		setIsAuthenticated: (state, action) => {
			state.isLoggedIn = ((value) =>
				value === '0' || value === 'false' ? false : Boolean(value))(
				action.payload
			);
		},
		setCSRF: (state, action) => {
			state.csrfKey = action.payload.csrfKey;
			state.csrfValue = action.payload.csrfValue;
		},
		set_user: (state, action) => {
			state.user = action.payload;
        },
        reset: (state, action)=>_.cloneDeep(_authInitialState)
	},
});

export const { update_login, logout, setIsAuthenticated, setCSRF, set_user } =
	AuthSlice.actions;
export default AuthSlice.reducer;
